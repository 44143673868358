<template>

    <template v-for="(navMenu,idnex) in navMenus">
      <el-sub-menu
       
        :key="navMenu.url"
        :data="navMenu"
        :index="navMenu.label"
        v-if="navMenu.children && navMenu.children.length>0" 
      >
        <template #title>
          <i :class="'iconfont '+navMenu.icon"></i>
        <!-- <el-icon>
        <component :is="navMenu.icon" />
        </el-icon> -->
    
          <span class="nav_item" :style="menuTxt"> {{ navMenu.label }} </span>
        </template>

    
              <NavMenu :navMenus="navMenu.children"></NavMenu>

      </el-sub-menu>

        <el-menu-item   :data="navMenu" :key="idnex" :index="navMenu.url"   v-else>
      <!-- <el-icon><setting /></el-icon> -->
 
           <template #title >   
          <template v-if="navMenu.value==0">
          <i :class="'iconfont '+navMenu.icon"></i>
          <span class="nav_item" :style="menuTxt"> {{ navMenu.label }} </span>
          </template>
            <template v-else>
            <span class="nav_item" :style="menuTxt"> {{ navMenu.label }} </span>
          </template>
  
      </template>

    
    </el-menu-item>

            <!-- <el-menu-item
  
        :key="idnex"
        :data="navMenu"
        :index="navMenu.url"

        
      >
           <template #title>
        <i :class="navMenu.icon"></i>
        <span >{{ navMenu.label }}</span>
           </template>
      </el-menu-item> -->

    </template>

</template>

<script>
import {reactive,ref} from 'vue'
export default {
  name: "NavMenu",
  props: ["navMenus"],
  setup(props){
      console.log(props.navMenus)
     let menuTxt=ref('')
    // const  pathTo=(path)=>{
    //     console.log(path)
    //   if (path) {
    //     this.$router.push(path);
    //   }
    // }

    return{
//    pathTo,
   menuTxt
    }
  },



};
</script>
<style lang="scss" scoped>
    .nav_title{
    font-size: 16px;
font-family: PingFang SC-Bold, PingFang SC;
/* font-weight: bold; */
/* color: #2E353E; */
font-weight: 600;
cursor: default;


}
.nav_item{
font-size: 16px;
font-family: PingFang SC-Bold, PingFang SC;
margin-left: 15px;
cursor: pointer;

}
:deep(.el-menu-item){
  font-size: 16px;
 
}
// :deep( .el-menu-vertical-demo:not(.el-menu--collapse)) {
//   width: 200px;
//   min-height: 400px;
// }
</style>