<template>
  <div class="layout_container">
    <section>
      <el-container>
        <el-aside class="layout_aside" width="200px">
          <div class="aside_logo flex_center">
            <img src="../../assets/img/complete_logo.png" />
          </div>
          <!-- #022C63 -->
          <el-menu
            router
            class="aside_menu"
            :default-active="$route.path"
            text-color="#FFFFFF"
            background-color="#0B183C"
            active-text-color="#0081FF"
            :collapse="isCollapse"
            :default-openeds="openeds"
            :collapse-transition="false"
            @open="handleOpen"
          >
            <menus :navMenus="menuList"></menus>
          </el-menu>
        </el-aside>

        <el-container>
          <el-header class="layout_header flex_between">
            <img class="expand" src="../../assets/img/expand.png" />

            <div class="aside_my flex_start">
              <el-input
                v-model="input2"
                placeholder="搜索你想要的内容"
                prefix-icon="Search"
              />
              <el-divider direction="vertical" />
              <el-badge is-dot type="danger" class="icon_msg">
                <img class="icon_item" src="../../assets/img/message.png" />
              </el-badge>

              <img class="icon_item" src="../../assets/img/skin.png" />
              <el-divider direction="vertical" />

              <el-badge is-dot type="success" class="portrait">
                <img src="../../assets/img/message.png" />
              </el-badge>
            </div>
          </el-header>
          <el-main class="layout_main">
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </section>
  </div>
</template>

<script>
import menus from "./components/NavMenu/NavMenu.vue";
import { reactive, ref } from "vue";
export default {
  name: "layout",
  components: { menus },
  props: {},
  setup() {
    let menuList = reactive([
      {
        label: "市场行情看板",
        value: 0,
        icon: "icon-gongzuotaiweixuanzhong",
        children: [],
        url: "/workbench",
      },
      {
        label: "日前交易",
        value: 1,
        icon: "icon-a-dingweiditudaohangweizhiduijiao-17",
        children: [
          {
            label: "运行日对比",
            value: 3,
            url: "/operatingDay",
            children: [],
          },
          {
            label: "现货价格分析",
            value: 4,
            url: "/stock",
            children: [],
          },
          {
            label: "日前交易分析",
            value: 4,
            url: "/transactionDay",
            children: [],
          },
        ],
      },
      {
        label: "交易管理",
        value: 5,
        icon: "icon-a-dingweiditudaohangweizhiduijiao-17",
        children: [
          {
            label: "售电持仓看板",
            value: 6,
            url: "/electricitySale",
            children: [],
          },
          {
            label: "中长期模拟交易",
            value: 7,
            url: "/simulation",
            children: [],
          },
          {
            label: "曲线分解方式",
            value: 7,
            url: "/curve",
            children: [],
          },
          {
            label: "合约估价",
            value: 7,
            url: "/evaluation",
            children: [],
          },
          {
            label: "电量分解对比",
            value: 7,
            url: "/decompose",
            children: [],
          },
        ],
      },
    ]);
    let isCollapse = ref(false);
    const handleOpen = (e, val) => {
      console.log(e);
      console.log(val);
    };
    return {
      menuList,
      isCollapse,
      handleOpen,
    };
  },
};
</script>

<style lang="scss" scoped>

.layout_header {
  width: 100%;
  background: #0b183c;
  height: 70px;
  box-shadow: inset -4px 0px 10px 0px #3767ad;
  .expand {
    width: 24px;
    height: 24px;
  }
  .aside_my {
    margin-right: 25px;
    :deep(.el-input .el-input__wrapper) {
      background: #161d26;
    }
    :deep(.el-divider--vertical) {
      margin: 0 22px;
      border-left: 1px solid #8291a9;
    }
    .icon_item {
      width: 24px;
      height: 24px;
      display: block;
    }
    .icon_msg {
      width: 24px;
      height: 24px;
    }
    .icon_item:nth-last-child(3) {
      margin-left: 20px;
    }
    .portrait {
      width: 36px;
      height: 36px;
      border-radius: 6px;
      & > img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
.layout_main {
  background: #090a1f;
}
.layout_aside {
  
  background: #0b183c;
  // min-height: calc(100vh - 70px);
  min-height:100vh;
  box-shadow: inset -10px 0px 10px -7px #3767ad;
  .aside_logo {
    width: 100%;
    height: 70px;
    & > img {
      height: 40px;
      width: 150px;
    }
  }
  &:deep(.el-menu) {
    width: 90%;
    // box-shadow: inset 0px 0px 10px 0px #3767AD;
    border-right: none;
  }
}
</style>